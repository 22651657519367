.checkBoxC{
  display: flex;
  align-items: center;
  justify-content: space-between;
  .label{
    color: 
    #737373;
    font-size: 16px;
    font-weight: 400;
  }
}
.toggle-switch {
  position: relative;
  display: inline-block;
  width: 45px;
  height: 23px;
}

.toggle-switch input[type="checkbox"] {
  display: none;
}

.toggle-switch .switch {
  position: absolute;
  cursor: pointer;
  background: #DADADA;
  border-radius: 25px;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  transition: background-color 0.2s ease;
}

.toggle-switch .switch::before {
  position: absolute;
  content: "";
  left: 2px;
  top: 3px;
  width: 18px;
  height: 17px;
  background-color: #ffffff;
  border-radius: 50%;
  transition: transform 0.3s ease;
}

.toggle-switch input[type="checkbox"]:checked+.switch::before {
  transform: translateX(23px);
  background-color: #ffffff;
}

.toggle-switch input[type="checkbox"]:checked+.switch {
  background-color: #1E1E1E;
}