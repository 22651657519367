@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700;800;900&display=swap');
*{
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
  font-family: 'Poppins', sans-serif;
}
h1,h2,h3,h4,h5{
  margin: 0px;
  padding: 0px;
}
p{
  margin: 0px;
  padding: 0px;
}
.customInput{
  border: 1px solid #E8E8E8;
  padding: 20px 20px;
  font-size: 17px;
  font-weight: 400;
  border-radius: 8px;
  width: 100%;
}
.customInput:focus{
  outline: none;
  box-shadow: none;
}
.customTextarea{
  border: 1px solid #E8E8E8;
  padding: 20px 20px;
  font-size: 17px;
  font-weight: 400;
  border-radius: 8px;
  width: 100%;
  min-height: 150px;
  resize: none;
}
.customTextarea:focus{
  outline: none;
  box-shadow: none;
}
&::-moz-placeholder {
  /* Firefox 19+ */
  color: #97A2B0;

}

&:-ms-input-placeholder {
  /* IE 10+ */
  color: #97A2B0;
}

&:-moz-placeholder {
  /* Firefox 18- */
  color: #97a2b0;
}
.inputClass{
  padding: 20px 20px 20px 20px !important;
  width: 100% !important;
  height: 68px !important;
  border: 1px solid #E8E8E8 !important;
  border-radius: 8px !important;
  padding-left: 60px !important;
}
.inputClass.error{
  border: 1px solid red;
}
.inputClass:focus{
  outline: none !important;
  box-shadow: none !important;
}
.flag-dropdown {
  background-color: transparent !important;
  border: 0px !important;
}
.flag-dropdown .selected-flag{
  padding: 0px !important;
  width: 60px !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}
.deleteModel{
  h6{
    font-size: 16px;
    color: #000;
    font-weight: 500;
    text-align: center;
    line-height: 24px;
    padding: 10px 0px;
  }
  .action{
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
    grid-gap: 10px;
    .cBtn {
      padding: 7px 10px;
      outline: none;
      border: none;
      background-color: transparent;
      width: 95px;
      font-size: 16px;
      font-weight: 400;
      border-radius: 6px;
      &.yes{
        background: #00E676;
        color: #fff;
      }
      &.no{
        background: #5B5D5D;

        color: #fff;
      }
    }
  }
}
::-webkit-scrollbar {
  width: 8px;
}
/* Track */
::-webkit-scrollbar-track {
  background: #fff;
}

::-webkit-scrollbar-thumb {
  background: #000;
  border-radius: 30px;
}
.custom-date-input {
  border: 1px solid rgba(34, 23, 71, 0.1);
  border-radius: 8px;
  width: 100%;
  margin-right: 16px;
  font-size: 14px;
  line-height: 16px;
  color: #000000;
  display: flex;
  align-items: center;
  padding: 18px 48px 12px 16px;
  position: relative;
  min-height: 48px;
  background-color: #fff;
}

.custom-date-input .custom-date-placeholder {
  color: #737373;
  font-size: 16px;
  font-weight: 400;
}

.custom-date-input .icon-calender {
  display: none;
}
.quill {
  border-radius: 10px;
  background-color: #ffffff;
}

.quill .ql-toolbar {
  padding: 10px;
}
.quill .ql-container .ql-editor{
  min-height: 200px;
  resize: none;
}
.quill .ql-container .ql-editor::before {
  font-style: normal;
  padding: 0px 0px 0px 0px;
  outline: none;
  font-size: 16px;
  font-weight: 500;
}
.react-datepicker-wrapper{
  width: 100%;
  text-align: left;
}
.mapContainer {
  width: 100%;
  height: 500px;
  position: relative;
}
#map11::after{
  width: 32px;
    height: 36px;
    display: block;
    content: " ";
    position: absolute;
    top: 50%;
    left: 50%;
    margin: -40px 0 0 -11px;
    /* background: url("../../../images/mapicon/map_pin.svg"); */
    background-size: 32px 36px;
    /* Since I used the HiDPI marker version this compensates for the 2x size */
    pointer-events: none;
    /* This disables clicks on the marker. Not fully supported by all major browsers, though */
    background-repeat: no-repeat;
}
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}