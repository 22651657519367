table {
  width: 100%;
  border-collapse: collapse;
  overflow: auto;
}

table thead tr th {
  background-color: #142328;
  padding: 14px 8px;
  font-size: 16px;
  color: #ffffff;
  font-weight: 400;
  text-align: center;
  white-space: nowrap;
  position: sticky; /* Make thead sticky */
  top: 0; /* Stick to the top of the table */
  z-index: 1; /* Ensure thead is above tbody */
}

table tbody tr td {
  padding: 14px 8px;
  font-size: 16px;
  color: #000000;
  font-weight: 400;
  text-align: center;
  white-space: nowrap;
}

/* Responsive table */
@media (max-width: 768px) {
  table {
    display: block;
    overflow-x: auto; /* Enable horizontal scrolling */
    white-space: nowrap; /* Prevent line breaks */
  }

  table thead,
  table tbody,
  table tr,
  table th,
  table td {
    display: block;
    text-align: center;
    padding: 6px;
  }

  table tbody tr {
    border-bottom: 1px solid #ccc; /* Add bottom border to separate rows */
  }

  table tbody tr:nth-of-type(even) {
    background-color: #f9f9f9; /* Alternate row background color */
  }

  table thead {
    position: absolute;
    top: -9999px; /* Hide thead on small screens */
    left: -9999px;
  }

  table tr {
    border: 1px solid #ccc; /* Add border around table and cells */
  }

  table th,
  table td {
    display: inline-block; /* Use inline-block for table cells */
    min-width: 100px; /* Set a minimum width for table cells */
    vertical-align: top; /* Align content to the top of cells */
  }
}
.paginationDiv {
  display: flex;
  align-items: center;
  padding: 10px 20px 0px 20px;
  justify-content: flex-end;
}

.paginationDiv .prevArrowButton {
  border: 1px solid black;
  border-radius: 8px;
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #000000;
}

.paginationDiv .prevArrowButton svg {
  color: #fff;
}
.paginationDiv .prevArrowButton:disabled{
  background: transparent;
}
.paginationDiv .prevArrowButton:disabled svg {
  color: #000;
}
.paginationDiv .nextArrowButton {
  border: 1px solid black;
  border-radius: 8px;
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #000000;
}

.paginationDiv .nextArrowButton svg {
  color: #ffffff;
}

.paginationDiv .nextArrowButton:disabled {
  background: none;
  border: 1px solid #000000;
}

.paginationDiv .nextArrowButton:disabled svg {
  color: #000000;
}

.paginationDiv p {
  color: #868589;
  font-size: 16px; /* Assuming $FONT16 is a variable for font size */
  margin: 0px 20px 0px 20px;
  padding: 0px;
}

.paginationDiv p .text-success {
  color: #000000 !important;
}

.paginationDiv .paginationCountInput {
  margin: 0px 20px 0px 0px;
}

.paginationDiv .paginationCountInput input {
  outline: none !important;
  box-shadow: none !important;
}

.paginationDiv .paginationCountInput .text-success {
  border: 1px solid #e7e7e7;
  border-radius: 8px;
  padding: 5px;
  color: #000000 !important;
}

.paginationDiv .paginationCountInput .text-success:focus {
  box-shadow: none !important;
  outline: none;
}
