.left-sidebar {
  width: 100%;
  max-width: 300px;
  background: #142328;
  height: 100vh;
  position: static;
  top: 0;
}
.left-sidebar .sidebarContainer {
  padding: 20px 0px;
  position: relative;
  height: 100%;
}
.left-sidebar .sidebarContainer .brand-logo {
  padding: 20px 0px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.left-sidebar .sidebarContainer .navigationContent {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow-y: auto;
  height: calc(100% - 170px);
}
.left-sidebar .sidebarContainer .navigationContent::-webkit-scrollbar {
  width: 0 !important;
}
.left-sidebar .sidebarContainer .navigationContent .nav_Item {
  margin: 0px;
  padding: 0px;
}

.left-sidebar .sidebarContainer .navigationContent .nav_Item li {
  list-style: none;
}

.left-sidebar
  .sidebarContainer
  .navigationContent
  .nav_Item
  li
  .submenuLink {
  cursor: pointer;
  background-color: transparent;
  text-decoration: none;
}

.left-sidebar
  .sidebarContainer
  .navigationContent
  .nav_Item
  li
  .submenuLink
  .iconLink {
  width: 100%;
  display: flex;
  align-items: center;
  padding: 20px 20px;
}

.left-sidebar
  .sidebarContainer
  .navigationContent
  .nav_Item
  li
  .submenuLink
  .iconLink
  .text {
  font-size: 18px;
  color: #ffffff;
  font-weight: 600;
}

.left-sidebar
  .sidebarContainer
  .navigationContent
  .nav_Item
  li
  .submenuLink
  .iconLink
  .icon {
  margin-right: 10px;
}

.left-sidebar
  .sidebarContainer
  .navigationContent
  .nav_Item
  li
  .submenuLink
  .iconLink.active {
  background: #00e67630;
}

.left-sidebar .sidebarContainer .bottomDetails {
  position: absolute;
  bottom: 0px;
  width: 100%;
}

.left-sidebar .sidebarContainer .bottomDetails .action {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: flex-start;
}
.left-sidebar .sidebarContainer .bottomDetails .action span {
  font-size: 18px;
  color: #ffffff;
  font-weight: 600;
  margin-left: 10px;
}
.left-sidebar .sidebarContainer .bottomDetails .action .logoutIcon {
  width: 24px;
}

.left-sidebar .sidebarContainer .bottomDetails .action .logoutIcon {
  width: 36px;
}
