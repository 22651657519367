.customDropdown {
  margin-right: 16px;
  width: 100%;
}

.customDropdown .dropdown {
  width: 100%;
  text-align: left;
}

.customDropdown .dropdown .dropdown-toggle {
  position: relative;
  text-align: left;
  width: 100%;
  padding: 30px 36px 30px 16px;
  line-height: 0;
  background-color: #ffffff;
  color: #929292;
  font-size: 16px;
  border: 1px solid rgba(34, 23, 71, 0.1);
  border-radius: 8px;
}

.customDropdown .dropdown .dropdown-toggle:focus {
  outline: none;
  box-shadow: none;
}

.customDropdown .dropdown .dropdown-toggle::after {
  margin: 0;
  position: absolute;
  right: 14px;
  top: 27px;
}

.customDropdown .dropdown .dropdown-menu {
  margin: 0 !important;
  padding: 0;
  border: 0;
  overflow: hidden;
  box-shadow: 0px 24px 48px rgba(0, 0, 0, 0.05);
  -webkit-box-shadow: 0px 24px 48px rgba(0, 0, 0, 0.05);
  -moz-box-shadow: 0px 24px 48px rgba(0, 0, 0, 0.05);
  -ms-box-shadow: 0px 24px 48px rgba(0, 0, 0, 0.05);
  -o-box-shadow: 0px 24px 48px rgba(0, 0, 0, 0.05);
  width: 100%;
}

.customDropdown .dropdown .dropdown-menu.show {
  position: absolute;
  margin: 0px;
  max-height: 226px;
  overflow-y: auto;
  z-index: 99 !important;
  scrollbar-color: #e4e4e4;
  scrollbar-width: thin;
}

.customDropdown .dropdown .dropdown-menu.show::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 7px;
  height: 7px;
}

.customDropdown .dropdown .dropdown-menu.show::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: #e4e4e4;
  -webkit-box-shadow: 1px rgba(255, 255, 255, 0.5);
}

.customDropdown .dropdown .dropdown-menu .dropdownData {
  margin: 0 !important;
}

.customDropdown .dropdown .dropdown-menu .dropdownData .dropdown-item {
  color: #1E1E1E;
  font-size: 14px;
  padding: 10px 14px;
}

.customDropdown .dropdown .dropdown-menu .dropdownData .dropdown-item:active {
  background-color: transparent;
}

.customDropdown .dropdown .dropdown-menu .dropdownData .dropdown-item:hover {
  text-decoration: none;
}

.customDropdown .dropdown .dropdown-menu .dropdownData .dropdown-item:last-child {
  border-bottom: 0;
}

.customDropdown.colorChange .dropdown button.btn-primary {
  color: #1e1e1e57;
}
