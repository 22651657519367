.setting {
    padding: 15px 15px 24px 15px;
}
.setting .titleSection .title {
    font-size: 28px;
    font-weight: 500;
    color: #000000;
    font-family: 'Poppins', sans-serif;
}
.setting .nav {
    border: none !important;
    padding-top: 24px;
}
.setting .nav .nav-item .nav-link {
    border: none;
    outline: none;
    color: #737373;
    font-size: 16px;
    font-weight: 500;
    cursor: pointer;
}
.setting .nav .nav-item .nav-link.active {
    background-color: #142328;
    color: #ffffff;
    border-radius: 8px 8px 0px 0px;
}
.setting .tab-content {
    padding: 24px 0px 24px 0px;
}
